import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';

import { RootState } from '@/store';
import { updatePickEmLiveEventStat as updatePickEmLiveEventStatAction } from '@/store/modules/pick-em/actions';

import PusherEventContext from '@/components/contexts/pusher-events';
import OverUnderListCell from '@/components/molecules/over-under-list-cell';
import {
  AppearanceLine,
  ConstructedPickEmOverUnderLineAppearance,
  SelectedOverUnder,
} from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { StatLineUpdateResponse } from '@/interfaces/live';
import { PickLocation } from '@/interfaces/pick-em';
import { RedeemablePowerUp } from '@/interfaces/power-ups';

import ExpandablePicksButton from './components/expandable-picks-button';

export interface OverUnderListProps {
  appearanceLines: AppearanceLine[];
  constructedPickEmLineAppearance: ConstructedPickEmOverUnderLineAppearance;
  pickLocation: PickLocation;
  powerUp: RedeemablePowerUp;
  selectedOverUnders: SelectedOverUnder[];
  updatePickEmLiveEventStat: ({
    data,
    appearanceId,
  }: {
    data: StatLineUpdateResponse;
    appearanceId: string;
  }) => void;
}

const DISPLAY_COUNT = 4;
const MAX_DISPLAY_COUNT = DISPLAY_COUNT + 1;

const OverUnderList = (props: OverUnderListProps) => {
  const {
    appearanceLines,
    constructedPickEmLineAppearance: cPEOULA,
    pickLocation,
    powerUp,
    selectedOverUnders,
    updatePickEmLiveEventStat,
  } = props;
  const [expanded, setExpanded] = useState(false);
  const pusher = useContext(PusherEventContext);

  const statLineCallback = useCallback(
    (data: StatLineUpdateResponse) => {
      if (cPEOULA.id === data.stat_line?.owner_id) {
        updatePickEmLiveEventStat({ data, appearanceId: cPEOULA.id });
      }
    },
    [cPEOULA.id, updatePickEmLiveEventStat]
  );

  useEffect(() => {
    const appearanceId = cPEOULA.id;
    if (pusher && appearanceId && cPEOULA.hasLiveLines) {
      let channel = pusher.channel(`cache-appearance-stats-${appearanceId}`);
      if (!channel) {
        channel = pusher.subscribe(`cache-appearance-stats-${appearanceId}`);
      }
      channel.unbind('stat_line_update'); // only bind the event once
      channel.bind('stat_line_update', statLineCallback);
    }

    return () => {
      if (pusher?.channel(`cache-appearance-stats-${appearanceId}`)) {
        pusher.unsubscribe(`cache-appearance-stats-${appearanceId}`);
      }
    };
  }, [cPEOULA, pusher, statLineCallback]);

  const overUnderListCells = useMemo(() => {
    const filteredAppearanceLines: JSX.Element[] = [];
    appearanceLines.forEach((appearanceLine) => {
      const selectedOverUnder = selectedOverUnders?.find(
        (sOU) => sOU.overUnderLineId === appearanceLine.id
      );

      if (pickLocation === PickLocation.REVIEW && !selectedOverUnder) {
        return;
      }

      filteredAppearanceLines.push(
        <OverUnderListCell
          key={appearanceLine.id}
          appearanceLine={appearanceLine}
          constructedPickEmLineAppearance={cPEOULA}
          pickLocation={pickLocation}
          powerUp={powerUp}
          selectedOverUnder={selectedOverUnder}
        />
      );
    });

    return filteredAppearanceLines;
  }, [appearanceLines, pickLocation, selectedOverUnders, cPEOULA, powerUp]);

  if (pickLocation !== PickLocation.LOBBY) {
    return overUnderListCells;
  }

  // If there are only 5 projections show all 5 and don't show the 'more picks' button
  // If there are more than 5 projections only show 4 and show the 'more picks' button
  const displayCount =
    overUnderListCells.length > MAX_DISPLAY_COUNT ? DISPLAY_COUNT : MAX_DISPLAY_COUNT;

  const cellsToShow = overUnderListCells.slice(0, displayCount);
  const remainingCellsToShow = expanded ? overUnderListCells.slice(displayCount) : [];

  return (
    <>
      {cellsToShow}
      <AnimatePresence>
        {expanded && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            transition={{ duration: 0.25 }}
            style={{ overflow: 'hidden' }}
          >
            {remainingCellsToShow.map((cell, index) => (
              <motion.div
                key={cell.key}
                initial={{ opacity: expanded ? 0 : 1, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.15, delay: index * 0.05 }}
              >
                {cell}
              </motion.div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
      {overUnderListCells.length > MAX_DISPLAY_COUNT && (
        <ExpandablePicksButton
          expanded={expanded}
          expandablePickNum={overUnderListCells.length - DISPLAY_COUNT}
          setExpanded={setExpanded}
        />
      )}
    </>
  );
};

export default connect(
  (state: RootState) => ({
    powerUp: state.pickEmEntries.selected.powerUp,
    selectedOverUnders: state.pickEmEntries.selected.selectedOverUnders,
  }),
  (dispatch) => ({
    updatePickEmLiveEventStat: ({
      data,
      appearanceId,
    }: {
      data: StatLineUpdateResponse;
      appearanceId: string;
    }) =>
      dispatch(
        updatePickEmLiveEventStatAction({
          data,
          appearanceId,
        })
      ),
  })
)(OverUnderList);
