import { useMediaQuery } from 'react-responsive';

import { Breakpoint, getBreakpointValue } from '../responsiveness-helpers';

const useBreakpoint = (breakpoint: Breakpoint) => {
  const breakpointValue = getBreakpointValue(breakpoint);

  const isViewportWider = useMediaQuery({ minWidth: breakpointValue });
  const isViewportNarrower = useMediaQuery({ maxWidth: breakpointValue - 1 });

  return {
    isViewportWider,
    isViewportNarrower,
  };
};

export default useBreakpoint;
