import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '@/store';

import Icon from '@/components/atoms/icon';
import { ScoringSection } from '@/components/atoms/info-modal-components';
import { HandleCloseModal, useModal } from '@/components/atoms/modal';
import DialogModal from '@/components/molecules/dialog-modal';
import { ScoringTypes } from '@/interfaces/drafting-config';
import { OverUnderLine } from '@/interfaces/over-under-line';

import styles from './styles.scss';

export interface ScoringInfoButtonProps {
  overUnderLine: OverUnderLine;
  scoringTypes: ScoringTypes;
}

const ScoringInfoButton = (props: ScoringInfoButtonProps) => {
  const { overUnderLine, scoringTypes } = props;

  const openModal = useModal();

  const handleScoringModalClick = (oUL: OverUnderLine) => {
    const scoringType = scoringTypes?.[oUL.overUnder.scoringTypeId];
    if (scoringType) {
      openModal(({ handleCloseModal }: HandleCloseModal) => (
        <DialogModal
          handleCloseModal={handleCloseModal}
          title="Scoring"
          content={<ScoringSection scoringType={scoringType} displayTitle={false} />}
        />
      ));
    }
  };

  if (overUnderLine.overUnder?.scoringTypeId) {
    return (
      <button
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          handleScoringModalClick(overUnderLine);
        }}
        aria-label="Scoring info"
        className={styles.scoringInfoButton}
      >
        <Icon name="info" className={styles.scoringModalIcon} />
      </button>
    );
  }

  return null;
};

export default connect((state: RootState) => ({
  scoringTypes: state.draftingConfig.scoringTypes,
}))(ScoringInfoButton);
