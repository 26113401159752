import type { BrazeServiceConfig } from '@/services/braze/service';
import type { PaysafeServiceConfig } from '@/services/paysafe/service';
import type { PciProxyServiceConfig } from '@/services/pci-proxy/service';
import type { SiftServiceConfig } from '@/services/sift/service';

import type { AmpliServiceConfig } from '../ampli/service';

export type AppConfig = {
  environment: 'production' | 'staging' | 'development';
  services: {
    ampli: Pick<AmpliServiceConfig, 'environment'>;
    braze: BrazeServiceConfig;
    paysafe: PaysafeServiceConfig;
    pciProxy: PciProxyServiceConfig;
    sift: SiftServiceConfig;
  };
};

/**
 * IMPORTANT: be sure to reference environment variables via `process.env.XXXXXX`
 * instead of destructuring or indexed access. In other words, code should be:
 *
 * const myVar = process.env.MY_VAR;
 *
 * but not:
 * const [MY_VAR] = process.env;
 * or
 * const myVar = process.env['MY_VAR'];
 *
 * This ensures that the webpack DefinePlugin will properly replace env var
 * references with their actual values at build time.
 */

export async function getAppConfig() {
  const appEnv = getAppEnv();

  const config: AppConfig = {
    environment: appEnv,
    services: {
      ampli: {
        environment: appEnv === 'production' ? 'production' : 'development',
      },
      braze: getBrazeConfig(),
      paysafe: getPaysafeConfig(),
      pciProxy: getPciProxyConfig(),
      sift: getSiftConfig(),
    },
  };

  return config;
}

function getAppEnv() {
  const appEnv = process.env.APP_ENV as AppConfig['environment'];

  if (!appEnv) {
    throw new Error('APP_ENV is not set');
  }

  if (appEnv !== 'production' && appEnv !== 'staging' && appEnv !== 'development') {
    throw new Error('APP_ENV is an unexpected value');
  }

  return appEnv;
}

function getBrazeConfig() {
  const apiKey = process.env.BRAZE_API_KEY;
  validateEnv(apiKey, 'BRAZE_API_KEY');

  const baseUrl = process.env.BRAZE_SDK_ENDPOINT;
  validateEnv(baseUrl, 'BRAZE_SDK_ENDPOINT');

  const safariWebsitePushId = process.env.BRAZE_SAFARI_DOMAIN;
  validateEnv(safariWebsitePushId, 'BRAZE_SAFARI_DOMAIN');

  const config: BrazeServiceConfig = {
    apiKey,
    baseUrl,
    safariWebsitePushId,
  };

  return config;
}

function getPaysafeConfig() {
  const apiKey = process.env.PAYSAFE_API_KEY;
  validateEnv(apiKey, 'PAYSAFE_API_KEY');

  const scriptUrl = process.env.PAYSAFE_SCRIPT_URL;
  validateEnv(scriptUrl, 'PAYSAFE_SCRIPT_URL');

  // review: we probably don't need to convert these values to numbers
  // The Paysafe SDK defines their types as `number`, but we created those types
  // and it's likely the Paysafe SDK doesn't validate them as numbers.
  // Not anything we need to spend a lot of time on, but something to consider.
  const paysafeMerchantId = process.env.PAYSAFE_MERCHANT;
  validateEnv(paysafeMerchantId, 'PAYSAFE_MERCHANT');
  const resolvedPaysafeMerchantId = Number(paysafeMerchantId);
  if (Number.isNaN(resolvedPaysafeMerchantId)) {
    throw new Error('PAYSAFE_MERCHANT must be a number');
  }

  const worldpayMerchantId = process.env.WORLDPAY_MERCHANT;
  validateEnv(worldpayMerchantId, 'WORLDPAY_MERCHANT');
  const resolvedWorldpayMerchantId = Number(worldpayMerchantId);
  if (Number.isNaN(resolvedWorldpayMerchantId)) {
    throw new Error('WORLDPAY_MERCHANT must be a number');
  }

  const environment = process.env.PAYSAFE_ENVIRONMENT;
  validateEnv(environment, 'PAYSAFE_ENVIRONMENT');

  const config: PaysafeServiceConfig = {
    apiKey,
    scriptUrl,
    paysafeMerchantId: resolvedPaysafeMerchantId,
    worldpayMerchantId: resolvedWorldpayMerchantId,
    environment,
    currencyCode: 'USD',
  };

  return config;
}

function getPciProxyConfig() {
  const merchantId = process.env.SECURE_FIELDS_INIT_KEY;
  validateEnv(merchantId, 'SECURE_FIELDS_INIT_KEY');

  const scriptUrl = process.env.PCI_PROXY_URL;
  validateEnv(scriptUrl, 'PCI_PROXY_URL');

  const config: PciProxyServiceConfig = {
    merchantId,
    scriptUrl,
  };

  return config;
}

function getSiftConfig() {
  const scriptUrl = process.env.SIFT_SCRIPT_URL;
  validateEnv(scriptUrl, 'SIFT_SCRIPT_URL');

  const accountId = process.env.SIFT_ACCOUNT_ID;
  validateEnv(accountId, 'SIFT_ACCOUNT_ID');

  const config: SiftServiceConfig = {
    scriptUrl,
    accountId,
  };

  return config;
}

function validateEnv(condition: unknown, envVarName: string): asserts condition {
  if (condition) {
    return;
  }

  throw new Error(`${envVarName} is not set`);
}
