import axios, { AxiosRequestConfig, AxiosResponse, isAxiosError } from 'axios';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

import { auth0Service } from '@/components/contexts/auth/auth0-service';
import type { ApiErrorDataResponse } from '@/interfaces/error';
import eventLogger from '@/utilities/analytics';
import {
  AUTH0_SESSION_REFRESH,
  MOBILE_UD_CLIENT_TYPE,
  MOBILE_UD_CLIENT_VERSION,
  MOBILE_UD_REF,
  UD_REF,
} from '@/utilities/constants';
import { APIError } from '@/utilities/errors';
import errorLogger from '@/utilities/errors/logger';
// eslint-disable-next-line import/no-cycle
import { getLocationHeaders } from '@/utilities/location';
import geoService from '@/utilities/location/geocomply';

export const clientType = Cookies.get(MOBILE_UD_CLIENT_TYPE) || 'web';
export const clientVersion =
  Cookies.get(MOBILE_UD_CLIENT_VERSION) || process.env.CLIENT_VERSION || 1;

const requestHeaders: { [key: string]: any } = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Client-Type': clientType,
  'Client-Version': clientVersion,
};

// The backend does some quality checking on the IP address obtained from
// client requests. If the IP resolves to a local address, e.g ::1, the quality
// check flags the IP as a VPN and the backend returns an error.
// We can work around this by setting a non-VPN IP address in the `x-forwarded-for`
// header. This is only necessary when the app targets a locally-running
// API server, because in that scenario the client IP address will always
// resolve to a local address. This doesn't occur when the app targets a remote
// API server, e.g. staging or production, because the client IP address will
// resolve to a public address, i.e. your home/work IP address.
// Caution: If you have this env var set while targeting staging or production
// you'll likely end up with a request error because the `x-forwarded-for`
// header is not accepted for those environments.
if (process.env.NON_VPN_IP && process.env.APP_ENV === 'development') {
  requestHeaders['X-Forwarded-For'] = process.env.NON_VPN_IP;
}

export const API_ENDPOINTS = {
  api: process.env.API_ENDPOINT || 'http://localhost:3000/api',
  stats: process.env.STAT_ENDPOINT || 'http://localhost:3000/stats',
};

export const getAuth = async (): Promise<string | undefined> => {
  // devise token
  const deviseAccessToken = Cookies.get('session');
  if (deviseAccessToken) {
    return deviseAccessToken;
  }

  // get auth from auth0service
  const accessToken = await auth0Service.getAuth();
  return accessToken;
};

export const removeAuth = () => {
  Cookies.remove('session');
  auth0Service.removeRefreshToken();
  auth0Service.resetPendingRefresh();
  eventLogger({
    gtm: {
      eventName: 'log_out',
    },
  });
};

export const getReferral = () => Cookies.get(MOBILE_UD_REF) || Cookies.get(UD_REF) || null;

export const removeReferral = () => {
  Cookies.remove(MOBILE_UD_REF);
  Cookies.remove(UD_REF);
};

// update this with server/request
export interface RequestConfig extends AxiosRequestConfig {
  underdogAPIVersion?:
    | 'beta/v1'
    | 'beta/v2'
    | 'beta/v3'
    | 'beta/v4'
    | 'beta/v5'
    | 'beta/v6'
    | 'financial_orchestration/api/v1'
    | 'v1'
    | 'v2'
    | 'v3'
    | 'v4'
    | 'v5'
    | 'v6'
    | 'v7';
  underdogAPIEndpoint?: keyof typeof API_ENDPOINTS;
  requiresGeoComply?: boolean;
  requiresLatLong?: boolean;
}

// IMPORTANT: the `deviceIdResolver` variable and `setRequestDeviceIdResolver` function
// are temporary constructs only. They will eventually be replaced by request/api client
// middleware that will handle deviceId resolution. We're not ready to
// fully refactor the `request` module yet, but we have refactored the deviceId resolution
// such that the `deviceIdResolver` needs to be injected into the request module.
let deviceIdResolver: (() => string) | undefined;

export function setRequestDeviceIdResolver(resolver: () => string) {
  deviceIdResolver = resolver;
}

export type RequestService = typeof request;

const request = async (options: RequestConfig): Promise<AxiosResponse> => {
  const authToken = await getAuth();
  const referral = getReferral();
  const deviceId = deviceIdResolver ? deviceIdResolver() : '';
  let locationHeaders = {};

  const { lat, long } = await getLocationHeaders({
    required: options.requiresLatLong || options.requiresGeoComply,
  });
  locationHeaders = {
    'User-Latitude': lat,
    'User-Longitude': long,
  };

  if (options.requiresLatLong) {
    const token = geoService.getExistingTokenOrNull();
    if (token) {
      requestHeaders['User-Location-Token'] = token || '';
    }
  } else {
    requestHeaders['User-Location-Token'] = '';
  }

  if (options.requiresGeoComply) {
    const token = await geoService.getToken();
    if (token) {
      requestHeaders['User-Location-Token'] = token;
    }
  }

  try {
    const response = await axios.request({
      timeout: 60000,
      ...options,
      headers: {
        ...requestHeaders,
        ...locationHeaders,
        ...options.headers,
        'Referring-Link': referral || '',
        'Client-Device-Id': deviceId,
        'Client-Request-Id': uuidv4(),
        Authorization: authToken || '',
      },
      url: `${API_ENDPOINTS[options.underdogAPIEndpoint || 'api']}/${
        options.underdogAPIVersion || 'v1'
      }${options.url}`,
      params: {
        ...options.params,
      },
      paramsSerializer: {
        ...options.paramsSerializer,
      },
    });

    // update the devise auth cookie with every successful web response
    if (
      response.headers.authorization &&
      clientType === 'web' &&
      !Cookies.get(AUTH0_SESSION_REFRESH)
    ) {
      Cookies.set('session', response.headers.authorization);
    }

    geoService.setLocationExpiry(response.headers);

    return response;
  } catch (err) {
    // If the error is not an instance of Error, we shouldn't treat it as an API error.
    // Instead, we log it and throw an actual Error that can be better handled by callers.
    if (!(err instanceof Error)) {
      const newError = new Error('Unknown error type encountered', { cause: err });
      errorLogger(true, newError);
      throw newError;
    }

    // If the error is not an AxiosError or doesn't match the response type we expect,
    // then we log it and re-throw it as-is.
    // `isAxiosError` only checks if the `err` object matches the AxiosError interface,
    // it doesn't check if the response data matches the type that we expect.
    // That is why, besides checking if `err` is an AxiosError, we also check if
    // `err.response.data` matches the `ApiErrorDataResponse` type.
    if (!isAxiosError(err) || !isApiErrorDataResponse(err.response?.data)) {
      errorLogger(true, err);
      throw err;
    }

    // If we've made it this far, then we have some certainty that the error is an API error
    // and can log it and throw an `APIError` instance.
    errorLogger(true, err, {
      url: err.config?.url || 'unknown',
      source: 'request',
    });

    // `http_status_code` _should_ always be defined on the error object, but
    // we check for it here just in case.
    const resolvedHttpStatusCode = err.response.data.error.http_status_code ?? err.response.status;
    throw new APIError({
      ...err.response.data.error,
      http_status_code: resolvedHttpStatusCode,
    });
  }
};

export function isApiErrorDataResponse(data: unknown): data is { error: ApiErrorDataResponse } {
  const test = data as { error?: ApiErrorDataResponse };
  return Boolean(test.error);
}

export default request;
