import React from 'react';

import Button from '@/components/atoms/button';
import { UD_PICK_EM } from '@/utilities/constants';

import styles from './styles.scss';

export function ErrorFallbackBase({
  error,
  supportContent,
}: {
  error?: Error;
  supportContent: React.ReactNode;
}) {
  return (
    <div className={styles.errorFallback}>
      <h1 className={styles.title}>Woof!</h1>
      <p className={styles.message}>Something went wrong, try refreshing the page.</p>
      {error?.message ? <p className={styles.message}>{error.message}</p> : null}
      <p className={styles.message}>If the issue persists, please&nbsp;{supportContent}.</p>
      <Button
        width="intrinsic"
        size="regular"
        color="green"
        type="solid"
        onClick={() => {
          window.location.href = `${
            window.location.origin + window.location.pathname
          }?t=${new Date().getTime()}`; // force hard refresh by creating a new URL
          localStorage.removeItem(UD_PICK_EM);
        }}
        text="Refresh"
      />
    </div>
  );
}
