import { OverUnderLine } from '@/interfaces/over-under-line';
import { RedeemablePowerUp } from '@/interfaces/power-ups';
import { POWER_UP_SPECIAL_STAT_VALUE } from '@/utilities/constants';

export const getStatValues = ({
  overUnderLine,
  powerUp,
}: {
  overUnderLine: OverUnderLine;
  powerUp: RedeemablePowerUp | null;
}): { prevStatValue: string | null; statValue: string } => {
  if (powerUp?.type === 'special') {
    // Power up special
    return {
      prevStatValue: overUnderLine.oldStatValue || overUnderLine.statValue,
      statValue: POWER_UP_SPECIAL_STAT_VALUE,
    };
  }

  if (overUnderLine.nonDiscountedStatValue) {
    // Legacy boost discount
    return {
      prevStatValue: overUnderLine.nonDiscountedStatValue,
      statValue: overUnderLine.statValue,
    };
  }

  // default
  return {
    prevStatValue: null,
    statValue: overUnderLine.statValue,
  };
};
