import { IconTypes } from '@/components/atoms/icon';
import {
  ConstructedEntrySlipPickEmOverUnderAppearance,
  SelectedOverUnder,
} from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { LiveStatLines } from '@/interfaces/constructed-interfaces/constructed-slates';
import { AppErrorResponse } from '@/interfaces/error';
import { PickEmSlipType, Selection } from '@/interfaces/pick-em';
import { PickEmResult } from '@/interfaces/types';
import { UD_PICK_EM_SELECTED_VARIANT } from '@/utilities/constants';
import langHelper from '@/utilities/lang-helper';

export const getIGPToastMessage = ({
  selectedOverUnders,
  event,
  autoAcceptProjectionChanges,
}: {
  selectedOverUnders: SelectedOverUnder[];
  event: 'suspend' | 'unsuspend' | 'swap';
  autoAcceptProjectionChanges: boolean;
}) => {
  if (!selectedOverUnders || selectedOverUnders.length === 0) return null;

  const emoji = event === 'suspend' ? '⏳' : '⚡';
  const moreThanOnePlayer = selectedOverUnders.length > 1;

  const playerNames = selectedOverUnders.map((sOU) =>
    langHelper.getPlayerFullName(sOU.constructedOverUnderAppearance.player)
  );
  const playerNamesString = langHelper.formatListToString(playerNames);

  let updateText = '';
  if (event === 'suspend') {
    updateText = moreThanOnePlayer
      ? 'picks are refreshing. You can wait or remove the picks.'
      : 'pick is refreshing.  You can wait or remove the pick.';
  }
  if (event === 'unsuspend') {
    updateText = moreThanOnePlayer
      ? 'picks are live. There were no changes.'
      : 'pick is live. There were no changes.';
  }
  if (event === 'swap') {
    updateText = moreThanOnePlayer
      ? 'picks have refreshed. You can wait or remove the picks.'
      : 'pick has refreshed. You can wait or remove the pick.';
  }

  if (event === 'swap' && autoAcceptProjectionChanges) {
    updateText = 'Some of your picks have refreshed.';
    return `${emoji} ${updateText}`;
  }

  return `${emoji} Your ${playerNamesString} ${updateText}`;
};

export type ThemeString = 'Light' | 'Dark';

export const getResultIcon = ({
  result,
  selection,
  theme,
}: {
  result: PickEmResult;
  selection: Selection;
  theme: ThemeString;
}): IconTypes => {
  const { inPlay } = selection;

  switch (result) {
    case 'live':
      return `inPlay${theme}`;

    case 'won':
      return `correct${theme}`;

    case 'lost':
      return `incorrect${theme}`;

    case 'pushed':
    case 'rebooted':
      return `push${theme}`;

    case 'pending':
      if (inPlay) return `inPlay${theme}`;
      return 'unfilled';

    default:
      return 'unfilled';
  }
};

export const getOverUnderResultStatus = ({
  cA,
  liveStatLines,
}: {
  cA: ConstructedEntrySlipPickEmOverUnderAppearance;
  liveStatLines: LiveStatLines;
}): PickEmResult => {
  const statLine = liveStatLines?.[cA.id] || cA.statLine;

  const activeStatValue =
    cA.selection?.actualStatValue ||
    (cA.overUnder.appearanceStat.stat === 'fantasy_points'
      ? statLine?.scores?.find((score) => score.scoringTypeId === cA.overUnder?.scoringTypeId)
          ?.points
      : statLine?.data?.[cA.overUnder.appearanceStat.stat]);

  const result: PickEmResult =
    cA.selection.inPlay && cA.selection.result === 'pending' ? 'live' : cA.selection.result;
  // inplay true and pending false, result = selection.result (live, won, lost, pushed, rebooted)
  // inplay false and pending true, result = 'pending' (no border, icon is unfilled)

  if (cA.selection.rebooted) {
    return 'rebooted';
  }

  if (result === 'live') {
    if (
      parseFloat(activeStatValue) > parseFloat(cA.overUnderLineInfo?.statValue) &&
      cA.selectedOverUnderOption?.choice === 'higher'
    ) {
      return 'won';
    }
    if (
      parseFloat(activeStatValue) > parseFloat(cA.overUnderLineInfo?.statValue) &&
      cA.selectedOverUnderOption?.choice === 'lower'
    ) {
      return 'lost';
    }
  }
  return result;
};

// If the user has previously selected a payout variant, persist that selection
export const persistPayoutVariant = ({
  minInsuredPicks,
  slipType,
  togglePickEmInsurance,
  isForcedFlex,
}: {
  minInsuredPicks: number;
  slipType: PickEmSlipType;
  togglePickEmInsurance: ({
    slipType,
    minInsuredPicks,
  }: {
    slipType: PickEmSlipType;
    minInsuredPicks: number;
  }) => void;
  isForcedFlex: boolean;
}) => {
  const pickEmSelectedVariant = localStorage.getItem(UD_PICK_EM_SELECTED_VARIANT);
  if (slipType) {
    if (pickEmSelectedVariant === 'default' && slipType === 'insurance' && !isForcedFlex) {
      togglePickEmInsurance({
        slipType: 'default',
        minInsuredPicks,
      });
    } else if (pickEmSelectedVariant === 'flex' || isForcedFlex) {
      togglePickEmInsurance({
        slipType: 'insurance',
        minInsuredPicks,
      });
    }
  }
};

export const getEntrySlipNestedErrors = (errors: Array<AppErrorResponse> | undefined) => {
  return errors?.filter((error) => error.invalidOptions.length > 0) ?? [];
};
