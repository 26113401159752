import React, { MouseEvent, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { clsx } from 'clsx';

import { removeAirdropOffer as removeAirdropOfferAction } from '@/store/modules/pick-em/actions';

import { claimAirdrop as claimAirdropApi } from '@/api/users';
import Button from '@/components/atoms/button';
import Icon from '@/components/atoms/icon';
import { HandleCloseModal, useModal } from '@/components/atoms/modal';
import { useToast } from '@/components/atoms/toast';
import ContentfulAirdropInfo from '@/components/molecules/contentful-airdrop-info';
import CountdownLabel from '@/components/molecules/countdown-label';
import ErrorModal from '@/components/molecules/error-modal';
import ModalWrapper from '@/components/molecules/modal-wrapper';
import { AirdropOffer } from '@/interfaces/airdrop-offers';
import { CDN_HOST } from '@/utilities/constants';

import commonStyles from '../../common-styles.scss';
import styles from './styles.scss';

interface PromoCardAirdropProps {
  airdrop: AirdropOffer;
}

const PromoCardAirdrop = (props: PromoCardAirdropProps) => {
  const { airdrop } = props;
  const { contentfulInfoId, description, expiresAt, id, title } = airdrop;

  const openModal = useModal();
  const openToast = useToast();
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onAirdropClaim = (e: MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setIsLoading(true);
    claimAirdropApi({ airdropId: id })
      .then(() => {
        dispatch(removeAirdropOfferAction({ airdropOfferId: id }));
        openToast({
          message: (
            <div className={styles.toastMessage}>
              <Icon className={styles.checkIcon} name="checkCircle" />
              <p className={styles.text}>Bonus claimed!</p>
            </div>
          ),
          open: true,
          showCloseButton: true,
          variant: 'success',
        });
      })
      .catch((error) => {
        openModal(({ handleCloseModal }: HandleCloseModal) => (
          <ErrorModal error={error} handleCloseModal={handleCloseModal} />
        ));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const openInfoModal = () => {
    if (!isLoading) {
      openModal(({ handleCloseModal }: HandleCloseModal) => (
        <ModalWrapper handleCloseModal={handleCloseModal} hideCloseButton>
          <ContentfulAirdropInfo
            airdrop={airdrop}
            contentfulInfoId={contentfulInfoId}
            handleCloseModal={handleCloseModal}
            onAirdropClaim={onAirdropClaim}
          />
        </ModalWrapper>
      ));
    }
  };

  const keyboardSelect = (e: React.KeyboardEvent<HTMLElement>) => {
    if (containerRef.current === e.target) {
      if (e.key === ' ' || e.key === 'Enter') {
        e.preventDefault();
        openInfoModal();
      }
    }
  };

  return (
    <div
      ref={containerRef}
      className={clsx(commonStyles.promoCardWrapper, styles.promoCardAirdrop, {
        [styles.loading]: isLoading,
      })}
      style={{
        backgroundImage: `url(${CDN_HOST}/pickem/bonus-bg.png)`,
      }}
      onClick={openInfoModal}
      onKeyDown={keyboardSelect}
      role="button"
      tabIndex={0}
    >
      <img
        alt="Airdrop icon"
        className={styles.airdropIcon}
        src={`${CDN_HOST}/pickem/airdrop-icon.png`}
      />
      <div className={clsx(commonStyles.infoSection, styles.infoSection)}>
        {expiresAt && (
          <div className={styles.labelWrapper}>
            <CountdownLabel expiresAt={expiresAt} />
          </div>
        )}
        <div className={styles.content}>
          <h2 className={clsx(commonStyles.header, styles.header)}>
            {title}
            <Icon className={styles.infoIcon} name="info" />
          </h2>
          {description && <p className={clsx(commonStyles.text, styles.text)}>{description}</p>}
          <div className={styles.buttonWrapper}>
            <Button
              classNames={{
                button: clsx(styles.button, {
                  [styles.disabled]: isLoading,
                }),
              }}
              color="grey"
              onClick={onAirdropClaim}
              disabled={isLoading}
              size="regular"
              text="Claim bonus"
              type="solid"
              width="full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default PromoCardAirdrop;
