import React, { createContext, useContext, useMemo } from 'react';

import type { AppConfig } from './service';

export interface AppConfigContextValue {
  appConfig: AppConfig;
}

export const AppConfigContext = createContext<AppConfigContextValue | undefined>(undefined);

export type AppConfigContextProviderProps = React.PropsWithChildren<{
  appConfig: AppConfig;
}>;

export function AppConfigContextProvider({ appConfig, children }: AppConfigContextProviderProps) {
  const contextValue: AppConfigContextValue = useMemo(() => {
    return { appConfig };
  }, [appConfig]);

  return <AppConfigContext.Provider value={contextValue}>{children}</AppConfigContext.Provider>;
}

export function useAppConfigContext() {
  const context = useContext(AppConfigContext);

  if (!context) {
    throw new Error('useAppConfigContext must be used within an AppConfigContextProvider');
  }

  return context;
}
