import React from 'react';

import { ErrorFallbackBase } from '../base';

const ErrorFallbackWithoutServices = ({ error }: { error?: Error }) => {
  return (
    <ErrorFallbackBase
      error={error}
      supportContent={<a href="mailto:support@underdogfantasy.com">contact support</a>}
    />
  );
};

export default ErrorFallbackWithoutServices;
