/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';

import request, { RequestConfig } from '@request';
import { AlternateProjectionsResponse } from '@/interfaces/over-under-line';
import {
  EntrySlipRequest,
  EntrySlipsResponse,
  FavoritePlayersResponse,
  FeaturedLobbyResponse,
  FeaturedOverUnderLinesResponse,
  OverUnderLinesResponse,
  PayoutOutcomeRequest,
  PayoutOutcomeResponse,
  PickEmEntrySlipLimitsResponse,
  PickEmSearchResultsResponse,
  PoolV1StylesResponse,
  PoolV2StylesResponse,
  RivalLinesResponse,
  SharedEntrySlipResponse,
  ShareLinkResponse,
  UserPickEmPoolsLeaderboardEntrySlipResponse,
} from '@/interfaces/pick-em';
import { apiErrorTracking, AppError } from '@/utilities/errors';

export const submitEntrySlip = async ({
  entrySlip,
  confirmed,
}: {
  entrySlip: EntrySlipRequest;
  confirmed?: boolean;
}): Promise<any> => {
  const isPoolV2 = entrySlip.pickEmPoolStyleId !== null;

  // for classic pickem
  const defaultEntrySlip = {
    fee: entrySlip.fee,
    options: entrySlip.options,
    total_multiplier: entrySlip.multiplier,
    power_up_id: entrySlip.powerUpId,
    insured: entrySlip.insured,
  };

  // for champions aka poolsv2
  const poolV2EntrySlip = {
    ...defaultEntrySlip,
    pickem_pool_style_id: entrySlip.pickEmPoolStyleId,
  };

  const entry_slip = isPoolV2 ? poolV2EntrySlip : defaultEntrySlip;
  const response = await request({
    method: 'POST',
    url: '/entry_slips',
    underdogAPIVersion: 'v5',
    requiresGeoComply: true,
    data: {
      entry_slip,
    },
    params: {
      ...(confirmed ? { confirmed: true } : {}),
    },
  });

  return response;
};

export const getOverUnderLines = async ({
  isAlternateProjectionsEnabled,
}: {
  isAlternateProjectionsEnabled: boolean;
}): Promise<AxiosResponse<OverUnderLinesResponse>> => {
  let apiVersion: RequestConfig['underdogAPIVersion'] = 'beta/v5';

  if (isAlternateProjectionsEnabled) {
    apiVersion = 'beta/v6';
  }
  const response = await request({
    method: 'GET',
    underdogAPIVersion: apiVersion,
    url: '/over_under_lines',
  });

  return response;
};

export const getLiveOverUnderLines = async (): Promise<AxiosResponse<OverUnderLinesResponse>> => {
  // eslint-disable-next-line max-len
  const response = await request({
    method: 'GET',
    underdogAPIVersion: 'beta/v2',
    url: '/live_over_under_lines',
  });

  return response;
};

/*
 * Get featured lobby
 *
 * Returns the all content displayed under the feature tab
 */
export const getFeaturedLobby = async (): Promise<AxiosResponse<FeaturedLobbyResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIVersion: 'v7',
      url: '/pickem_lobby',
    });
    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getAlternateProjections = async ({
  overUnderId,
}: {
  overUnderId: string;
}): Promise<AxiosResponse<AlternateProjectionsResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/over_unders/${overUnderId}/alternate_projections`,
    });
    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getActiveEntrySlips = async ({
  page,
}: {
  page?: number;
}): Promise<AxiosResponse<EntrySlipsResponse>> => {
  const response = await request({
    method: 'GET',
    underdogAPIVersion: 'v6',
    url: '/user/active_entry_slips',
    params: {
      page,
    },
  });

  return response;
};

export const getSettledEntrySlips = async ({
  page,
}: {
  page?: number;
}): Promise<AxiosResponse<EntrySlipsResponse>> => {
  const response = await request({
    method: 'GET',
    underdogAPIVersion: 'v6',
    url: '/user/settled_entry_slips',
    params: {
      page,
    },
  });

  return response;
};

export const getEntrySlipLimits = async (): Promise<
  AxiosResponse<PickEmEntrySlipLimitsResponse>
> => {
  const response = await request({
    method: 'GET',
    url: '/user/entry_slip_limits',
    requiresLatLong: true,
  });

  return response;
};

export const getFavoritePickEmPlayers = async (): Promise<
  AxiosResponse<FavoritePlayersResponse>
> => {
  const response = await request({
    method: 'GET',
    url: '/user/favorite_players',
  });

  return response;
};

export const addFavoritePickEmPlayer = async (playerId: string): Promise<AxiosResponse<void>> => {
  const data = {
    favorite_player: {
      player_id: playerId,
    },
  };
  const response = await request({
    method: 'POST',
    url: '/user/favorite_players',
    data,
  });

  return response;
};

export const removeFavoritePickEmPlayer = async (
  playerId: string
): Promise<AxiosResponse<void>> => {
  const response = await request({
    method: 'DELETE',
    url: `/user/favorite_players/${playerId}`,
  });

  return response;
};

export const getRivalLines = async (): Promise<AxiosResponse<RivalLinesResponse>> => {
  const response = await request({
    method: 'GET',
    underdogAPIVersion: 'beta/v3',
    url: '/rival_lines',
  });

  return response;
};

export const createSlipShareLink = async ({
  entrySlipId,
}: {
  entrySlipId: string;
}): Promise<AxiosResponse<ShareLinkResponse>> => {
  try {
    const response = await request({
      method: 'POST',
      url: `/entry_slips/${entrySlipId}/share_link`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getSharedSlip = async ({
  entrySlipShareId,
}: {
  entrySlipShareId: string;
}): Promise<AxiosResponse<SharedEntrySlipResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIVersion: 'v4',
      url: `/entry_slips/${entrySlipShareId}/share`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getEntrySlipForImage = async ({
  entrySlipShareId,
}: {
  entrySlipShareId: string;
}): Promise<AxiosResponse<SharedEntrySlipResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIVersion: 'v3',
      url: `/entry_slips/${entrySlipShareId}/image`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getFeaturedOverUnderLines = async (): Promise<
  AxiosResponse<FeaturedOverUnderLinesResponse>
> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIVersion: 'v3',
      url: '/user/featured_over_under_lines',
      requiresLatLong: true,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

/*
 * Get payout outcome
 *
 * Returns the total payout multiplier values, fee limits, and validity of a given list of picks
 */
export const getSlipPayoutOutcome = async ({
  options,
  powerUpId,
}: {
  options: PayoutOutcomeRequest[];
  powerUpId: string;
}): Promise<AxiosResponse<PayoutOutcomeResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIVersion: 'v2',
      url: '/entry_slips/payout_outcome',
      params: {
        options,
        power_up_id: powerUpId,
      },
      paramsSerializer: {
        encode: (params) => params.replace(/(\[[0-9]])/g, '[]'),
      },
    });
    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getPoolV1Styles = async (): Promise<AxiosResponse<PoolV1StylesResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIVersion: 'v2',
      underdogAPIEndpoint: 'stats',
      url: '/pickem_pool_styles',
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getPoolV2Styles = async (): Promise<AxiosResponse<PoolV2StylesResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIVersion: 'v3',
      underdogAPIEndpoint: 'stats',
      url: '/pickem_pool_styles',
    });
    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getPickEmPoolLeaderboard = async ({
  page,
  pickEmPoolId,
}: {
  page?: number;
  pickEmPoolId: string;
}): Promise<AxiosResponse<EntrySlipsResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/pickem_pools/${pickEmPoolId}/leaderboard`,
      params: {
        page,
      },
    });

    return {
      ...response,
      data: {
        data: response.data.pickem_pool_leaderboard,
        meta: response.data.meta,
      },
    };
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getUserPickEmPoolsLeaderboardEntrySlip = async ({
  entrySlipId,
}: {
  entrySlipId: string;
}): Promise<AxiosResponse<UserPickEmPoolsLeaderboardEntrySlipResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/user/pickem_pools/entry_slips/${entrySlipId}`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const cancelEntrySlip = async ({
  entrySlipId,
}: {
  entrySlipId: string;
}): Promise<AxiosResponse<void>> => {
  try {
    const response = await request({
      method: 'POST',
      url: `/entry_slips/${entrySlipId}/cancellation`,
    });
    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getPickEmSearchResults = async ({
  sportId,
  searchObjectId,
}: {
  sportId: string | null;
  searchObjectId: string | null;
}): Promise<AxiosResponse<PickEmSearchResultsResponse>> => {
  const response = await request({
    method: 'GET',
    underdogAPIVersion: 'v2',
    url: '/pickem_search/search_results',
    params: {
      sport_id: sportId?.toUpperCase() || null, // must be uppercase
      algolia_object_id: searchObjectId,
    },
  });

  return response;
};

export const setGameplaySettings = async ({
  payoutDefault,
  autoAcceptProjectionChanges,
  userPromptedAt,
}: {
  payoutDefault: string;
  autoAcceptProjectionChanges: boolean;
  userPromptedAt: string;
}): Promise<AxiosResponse<void>> => {
  const data = {
    gameplay_settings: {
      payout_default: payoutDefault,
      auto_accept_projection_changes: autoAcceptProjectionChanges,
      user_prompted_at: userPromptedAt,
    },
  };
  const response = await request({
    method: 'PATCH',
    url: '/user/gameplay_settings',
    data,
  });

  return response;
};
