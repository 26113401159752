import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import styles from './styles.scss';

export interface LoaderProps {
  className?: string;
  message?: string;
  /**
   * When provided, the loader will not be shown until the delay (in milliseconds) has passed.
   * This is useful for preventing a flash of the loader when the loading operation is quick.
   * If not provided, the loader will be shown immediately.
   */
  delay?: number;
}

const Loader = (props: LoaderProps) => {
  const { className, delay, message } = props;

  // If delay is 0 or undefined, show the loader immediately
  const [showLoader, setShowLoader] = useState<boolean>(!delay);

  useEffect(() => {
    if (!delay) {
      return undefined;
    }

    const timeout = setTimeout(() => {
      setShowLoader(true);
    }, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  return showLoader ? (
    <div className={styles.loaderWrapper}>
      <div className={clsx(styles.loadingRing, className)}>
        <div />
        <div />
        <div />
        <div />
      </div>
      {message ? <p className={styles.message}>{message}</p> : null}
    </div>
  ) : null;
};

export default Loader;
