import React from 'react';

import { AlternateProjection } from '@/interfaces/over-under-line';

import styles from './styles.scss';

interface AltProjectionsCarouselItemProps {
  item?: AlternateProjection;
}

const AltProjectionsCarouselItem = (props: AltProjectionsCarouselItemProps) => {
  const { item } = props;
  const { options, statValue } = item;

  const higherChoice = options?.find((option) => option.choice === 'higher');
  const payoutMultiplierForHigher = higherChoice?.payoutMultiplier;
  const lowerChoice = options?.find((option) => option.choice === 'lower');
  const payoutMultiplierForLower = lowerChoice?.payoutMultiplier;

  return (
    <div className={styles.altsRow}>
      <div className={styles.option1}>
        {payoutMultiplierForHigher && `${payoutMultiplierForHigher}x`}
      </div>
      <div className={styles.stat}>{statValue}</div>
      <div className={styles.option2}>
        {payoutMultiplierForLower && `${payoutMultiplierForLower}x`}
      </div>
    </div>
  );
};

export default AltProjectionsCarouselItem;
