import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import Cookies from 'js-cookie';

import { RootState } from '@/store';
import {
  acceptOverUnderLineSwap as acceptOverUnderLineSwapAction,
  acceptSelectedOverUnderLineSwap as acceptSelectedOverUnderLineSwapAction,
  chooseAlternateProjection as chooseAlternateProjectionAction,
  UpdateSelectedArgs,
  updateSelectedOverUnders as updateSelectedOverUndersAction,
} from '@/store/modules/pick-em/actions';

import { HandleCloseModal, useModal } from '@/components/atoms/modal';
import PickEmButton from '@/components/atoms/pick-em-button';
import FadeAnimation from '@/components/molecules/animations/fade';
import TextSlideUpAndFadeAnimation from '@/components/molecules/animations/text-slide-up-and-fade';
import ModalWrapper from '@/components/molecules/modal-wrapper';
import {
  ConstructedPickEmOverUnderLineAppearance,
  SelectedOverUnder,
} from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { FeatureFlags } from '@/interfaces/feature-flags';
import { OverUnderLine, OverUnderOption } from '@/interfaces/over-under-line';
import { PickLocation } from '@/interfaces/pick-em';
import { SportId } from '@/interfaces/types';
import { User } from '@/interfaces/user';
import { UD_MAX_PAYOUT_MULTIPLIER_MODAL_DISMISSED } from '@/utilities/constants';
import { getMinInsuredPicks } from '@/utilities/helpers';

import MaxPayoutMultiplierModal from '../over-under-list-cell/components/max-payout-multiplier-modal-content';

import styles from './styles.scss';

export interface OptionsWrapperProps {
  acceptOverUnderLineSwap: ({ overUnderLineIds }: { overUnderLineIds: string[] }) => void;
  acceptSelectedOverUnderLineSwap: ({ optionIds }: { optionIds: string[] }) => void;
  allAltIds?: string[];
  autoAcceptProjectionChanges: boolean | null;
  chooseAlternateProjection: ({
    selectedAlternateLineId,
    selectedOptionId,
  }: {
    selectedAlternateLineId: string;
    selectedOptionId: string;
  }) => void;
  classNames?: {
    selectedOverride?: string;
    wrapper?: string;
    alignment?: string;
  };
  constructedPickEmLineAppearance: ConstructedPickEmOverUnderLineAppearance;
  isPickEmFeatureEnabled: FeatureFlags['pickEm'];
  overUnderLine: OverUnderLine;
  pickLocation: PickLocation;
  onSelectOption?: () => void;
  optionsLocation?: 'lobby' | 'playerCard' | 'altsModal';
  selectedOptionId?: string;
  selectedOverUnders: SelectedOverUnder[];
  swapLineOptionAndAnimate?: boolean;
  swapStatValueAndAnimate?: boolean;
  updateSelectedOverUnders: ({
    action,
    selection,
    overUnderLine,
    isPickEmFeatureEnabled,
    lobbyName,
    pickLocation,
    minInsuredPicks,
    maxUserPicks,
  }: UpdateSelectedArgs<SelectedOverUnder>) => void;
  user: User;
}

type ParamTypes = {
  sportId?: SportId;
};

const OptionsWrapper = (props: OptionsWrapperProps) => {
  const {
    acceptOverUnderLineSwap,
    acceptSelectedOverUnderLineSwap,
    allAltIds,
    autoAcceptProjectionChanges,
    chooseAlternateProjection,
    classNames,
    constructedPickEmLineAppearance: cPEOULA,
    isPickEmFeatureEnabled,
    onSelectOption,
    overUnderLine,
    pickLocation,
    optionsLocation,
    selectedOptionId,
    selectedOverUnders,
    swapLineOptionAndAnimate,
    swapStatValueAndAnimate,
    updateSelectedOverUnders,
    user,
  } = props;
  const isRemoved = overUnderLine?.status === 'removed';
  const { sportId } = useParams<ParamTypes>();

  const openModal = useModal();

  const [isAcceptButton, setIsAcceptButton] = useState<boolean>(false);
  const onlyMultiplierSwap =
    overUnderLine?.oldStatValue === overUnderLine?.statValue && overUnderLine?.oldLineOptions;
  const selectedOverUnder = selectedOverUnders?.find(
    (sOU) => sOU.overUnderLineId === overUnderLine?.id
  );

  const oULNeedsAcceptance = !!(
    overUnderLine?.needsAcceptanceForStatChange &&
    !onlyMultiplierSwap &&
    selectedOverUnder
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (autoAcceptProjectionChanges) {
      acceptOverUnderLineSwap({ overUnderLineIds: [overUnderLine.id] });
      acceptSelectedOverUnderLineSwap({ optionIds: [selectedOptionId] });
    }
    if (oULNeedsAcceptance) {
      const delay = setTimeout(() => {
        setIsAcceptButton(true);
      }, 0); // Delay in milliseconds before remounting original component
      return () => clearTimeout(delay);
    }
    if (!oULNeedsAcceptance) {
      setIsAcceptButton(false);
    }
  }, [
    acceptOverUnderLineSwap,
    acceptSelectedOverUnderLineSwap,
    autoAcceptProjectionChanges,
    oULNeedsAcceptance,
    overUnderLine.id,
    selectedOptionId,
  ]);

  const handleOptionClick = ({
    option,
    isRemoved: optionIsRemoved,
  }: {
    option: OverUnderOption;
    isRemoved: boolean;
  }) => {
    if (allAltIds?.includes(overUnderLine.id)) {
      chooseAlternateProjection({
        selectedAlternateLineId: overUnderLine.id,
        selectedOptionId: option.id,
      });
    }

    const selection: SelectedOverUnder = {
      overUnderLineId: option.overUnderLineId,
      option,
      boost: overUnderLine.overUnder.boost,
      powerUpId: null,
      constructedOverUnderAppearance: {
        ...cPEOULA,
        appearanceLines: [
          {
            id: option.overUnderLineId,
          },
        ],
      },
      overUnderLineInfo: {
        displayStat: overUnderLine.overUnder.appearanceStat.displayStat,
        statValue: overUnderLine.statValue,
        appearanceStat: overUnderLine.overUnder.appearanceStat,
        status: overUnderLine.status,
        liveEvent: overUnderLine.liveEvent,
        nonDiscountedStatValue: overUnderLine.nonDiscountedStatValue,
        altLineInfo: {
          isAlt: !overUnderLine.isMain,
          overUnderId: overUnderLine.overUnder.id,
          mainLineId: overUnderLine.id,
        },
      },
    };

    const action = selectedOptionId === option.id ? 'remove' : 'add';

    const hasExceededPayoutMultiplier =
      localStorage.getItem('max_payout_modifier_reached') === 'default' ||
      localStorage.getItem('max_payout_modifier_reached') === 'insurance';

    const hasDismissedMaxPayoutMessage = Cookies.get(UD_MAX_PAYOUT_MULTIPLIER_MODAL_DISMISSED);

    const updateSOUs = () => {
      const minInsuredPicks: number = getMinInsuredPicks(
        user.stateConfig?.pickEm.minimumSelectionSize
      );

      updateSelectedOverUnders({
        action: optionIsRemoved ? null : action,
        selection,
        overUnderLine,
        isPickEmFeatureEnabled,
        lobbyName: sportId,
        pickLocation,
        minInsuredPicks,
        maxUserPicks: user.stateConfig.pickEm.maximumSelectionSize,
      });
    };

    const isSamePlayerLine = selectedOverUnders.some(
      (sOU) => sOU.constructedOverUnderAppearance.id === selection.constructedOverUnderAppearance.id
    );
    if (
      option.payoutMultiplier !== 1 &&
      action === 'add' &&
      hasExceededPayoutMultiplier &&
      !isSamePlayerLine &&
      hasDismissedMaxPayoutMessage !== 'true'
    ) {
      openModal(({ handleCloseModal: close }: HandleCloseModal) => (
        <ModalWrapper handleCloseModal={close} hideCloseButton>
          <MaxPayoutMultiplierModal
            handleCloseModal={close}
            updateSelectedOverUnders={updateSOUs}
          />
        </ModalWrapper>
      ));
    } else {
      updateSOUs();
    }
    onSelectOption?.();
  };

  const acceptSwapButton = (
    <div className={styles.optionsContainer}>
      <PickEmButton
        className={styles.acceptButton}
        optionText="Accept"
        onClick={(e) => {
          e.stopPropagation();
          acceptOverUnderLineSwap({ overUnderLineIds: [overUnderLine.id] });
          acceptSelectedOverUnderLineSwap({ optionIds: [selectedOptionId] });
        }}
        selected={false}
      />
    </div>
  );

  const defaultButtons = (
    <div className={styles.optionsContainer}>
      {overUnderLine?.options?.map((option, index) => {
        let payoutMultiplier;
        let optionText;

        if (overUnderLine.oldLineOptions && overUnderLine.oldLineOptions?.[index]) {
          payoutMultiplier = overUnderLine.oldLineOptions[index].payoutMultiplier;
          optionText = overUnderLine.oldLineOptions[index].choiceDisplay;
        } else {
          payoutMultiplier = option.payoutMultiplier;
          optionText = option.choiceDisplay;
        }

        const currentMultiplier = (
          <span className={styles.payoutMultiplier}>{`${payoutMultiplier}x`}</span>
        );

        const updatedMultiplier = (
          <span className={styles.payoutMultiplier}>{`${option.payoutMultiplier}x`}</span>
        );

        const lineOptionText =
          option.payoutMultiplier !== 1 ? (
            <div className={styles.lineOption}>
              <span className={styles.optionText}>{optionText}</span>
              <span className={styles.payoutMultiplierWrapper}>
                <TextSlideUpAndFadeAnimation
                  swapCondition={swapLineOptionAndAnimate}
                  secondarySwapCondition={swapStatValueAndAnimate}
                  incomingContent={updatedMultiplier}
                  defaultContent={currentMultiplier}
                />
              </span>
            </div>
          ) : (
            option.choiceDisplay
          );

        return (
          <PickEmButton
            key={option.id}
            optionText={lineOptionText}
            className={clsx({
              [styles.playerModalPickEmButton]:
                pickLocation === PickLocation.PLAYER_CARD && optionsLocation !== 'altsModal',
              [styles.selected]: option.id === selectedOptionId,
              [classNames?.selectedOverride]: option.id === selectedOptionId,
            })}
            onClick={(e) => {
              e.stopPropagation();
              handleOptionClick({
                option,
                isRemoved,
              });
            }}
            selected={option.id === selectedOptionId}
          />
        );
      })}
    </div>
  );

  return (
    <div className={clsx(classNames?.wrapper, classNames?.alignment)}>
      <FadeAnimation
        swapCondition={isAcceptButton}
        defaultContent={defaultButtons}
        incomingContent={acceptSwapButton}
      />
    </div>
  );
};

export default connect(
  (state: RootState) => ({
    autoAcceptProjectionChanges: state.user.gameplaySettings?.autoAcceptProjectionChanges,
    isPickEmFeatureEnabled: state.featureFlags.pickEm,
    selectedOverUnders: state.pickEmEntries.selected.selectedOverUnders,
    user: state.user,
  }),
  (dispatch) => ({
    updateSelectedOverUnders: ({
      action,
      selection,
      overUnderLine,
      isPickEmFeatureEnabled,
      lobbyName,
      pickLocation,
      minInsuredPicks,
      maxUserPicks,
    }: UpdateSelectedArgs<SelectedOverUnder>) =>
      dispatch(
        updateSelectedOverUndersAction({
          action,
          selection,
          overUnderLine,
          isPickEmFeatureEnabled,
          lobbyName,
          pickLocation,
          minInsuredPicks,
          maxUserPicks,
        })
      ),
    acceptSelectedOverUnderLineSwap: ({ optionIds }: { optionIds: string[] }) =>
      dispatch(acceptSelectedOverUnderLineSwapAction({ optionIds })),
    acceptOverUnderLineSwap: ({ overUnderLineIds }: { overUnderLineIds: string[] }) =>
      dispatch(acceptOverUnderLineSwapAction({ overUnderLineIds })),
    chooseAlternateProjection: ({
      selectedAlternateLineId,
      selectedOptionId,
    }: {
      selectedAlternateLineId: string;
      selectedOptionId: string;
    }) =>
      dispatch(
        chooseAlternateProjectionAction({
          selectedAlternateLineId,
          selectedOptionId,
        })
      ),
  })
)(OptionsWrapper);
